import React, { useState } from 'react';
import { VAVulnerabilityProvider } from './VAVulnerabilityContext';
import VADashboard from './VADashboard';
import VAVulnerabilitiesTable from './VAVulnerabilitiesTable';
import VAUpload from './VAUpload';
import VulnerabilityDetailsModal from './VulnerabilityDetailsModal';
import { Box, Card, CardContent, CardHeader, Breadcrumbs, Typography, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const VAVulnerabilities = () => {
  const [selectedVulnerability, setSelectedVulnerability] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state for table data

  const handleRowClick = async (vulnerability) => {
    setSelectedVulnerability(vulnerability);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVulnerability(null);
  };

  return (
    <Box sx={{ padding: '20px',  minHeight: '100vh' }}>
      <VAVulnerabilityProvider>
        <Breadcrumbs aria-label="breadcrumb" sx={{ backgroundColor: '#f4f6f9',marginBottom: '20px' }}>
          <Link underline="hover" color="inherit" href="/">
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Typography color="text.primary">VA Vulnerabilities</Typography>
        </Breadcrumbs>
        <Typography variant="h6" sx={{ marginBottom: '20px', color: '#adb5bd' }}>
          VA Vulnerabilities
        </Typography>

        
         <VADashboard />
          

        <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
          <CardHeader
            title="Vulnerabilities"
            titleTypographyProps={{ variant: 'body1', color: '#adb5bd' }}
            sx={{ backgroundColor: '#343a40', color: '#adb5bd', padding: '10px 16px' }}
          />
          <CardContent>
            <Box sx={{ marginBottom: '20px' }}>
              <VAUpload />
            </Box>
            <VAVulnerabilitiesTable onRowClick={handleRowClick} setLoading={setLoading} />
          </CardContent>
        </Card>

        {selectedVulnerability && (
          <VulnerabilityDetailsModal
            show={showModal}
            handleClose={handleCloseModal}
            vulnerability={selectedVulnerability}
          />
        )}
      </VAVulnerabilityProvider>
    </Box>
  );
};

export default VAVulnerabilities;
