import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  Avatar,
  Typography,
  TextField,
  InputLabel,
  Tabs,
  Tab,
  AppBar,
  Divider,
  IconButton,
  Badge,
} from '@mui/material';
import { Save as SaveIcon, Upload as UploadIcon, Delete as DeleteIcon, Lock as LockIcon } from '@mui/icons-material';

const UserProfile = () => {
  const [user, setUser] = useState({});
  const [color, setColor] = useState('#ffffff');
  const [avatar, setAvatar] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const res = await axios.get('/api/users/me', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUser(res.data);
      setColor(res.data.color || '#ffffff');
      setAvatar(res.data.avatar);
      setFirstName(res.data.firstName);
      setLastName(res.data.lastName);
      setEmail(res.data.email);
    } catch (err) {
      console.error('Failed to fetch user:', err);
    }
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const handleSaveProfile = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        '/api/users/me',
        { firstName, lastName, email, color, avatar },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      toast.success('Profile updated successfully');
    } catch (err) {
      console.error('Failed to update profile:', err);
      toast.error('Failed to update profile');
    }
  };

  const handleAvatarChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setAvatar(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleRemoveAvatar = () => {
    setAvatar(null);
  };

  const handleSavePassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    try {
      await axios.put(
        '/api/users/change-password',
        { password },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      toast.success('Password updated successfully');
      setPassword('');
      setConfirmPassword('');
    } catch (err) {
      console.error('Failed to update password:', err);
      toast.error('Failed to update password');
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px', fontWeight: 'bold', color: '#343a40' }}>
        User Profile
      </Typography>

      <AppBar position="static" color="default">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Profile Details" />
          <Tab label="Change Avatar" />
          <Tab label="Password" />
        </Tabs>
      </AppBar>

      <Box sx={{ marginTop: 3 }}>
        {activeTab === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Edit Profile
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Pick a Color</InputLabel>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <Button
                          variant="outlined"
                          sx={{ backgroundColor: color, color: '#fff', marginRight: '10px' }}
                          onClick={() => setShowColorPicker(!showColorPicker)}
                        >
                          {showColorPicker ? 'Close Color Picker' : 'Select Color'}
                        </Button>
                        {showColorPicker && (
                          <Box sx={{ position: 'relative', zIndex: 2 }}>
                            <SketchPicker color={color} onChangeComplete={handleColorChange} />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    startIcon={<SaveIcon />}
                    onClick={handleSaveProfile}
                  >
                    Save Changes
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    Profile Preview
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    {avatar ? (
                      <Avatar
                        src={avatar}
                        alt="User Avatar"
                        sx={{ width: '120px', height: '120px', margin: 'auto', borderRadius: '50%' }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: color,
                          width: '120px',
                          height: '120px',
                          margin: 'auto',
                          fontSize: '48px',
                          color: '#fff',
                        }}
                      >
                        {firstName?.[0]}{lastName?.[0]}
                      </Avatar>
                    )}
                  </Box>
                  <Typography variant="h5" style={{ color }}>
                    {firstName} {lastName}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {email}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {activeTab === 1 && (
          <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Update Avatar
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="avatar-upload"
                  type="file"
                  onChange={handleAvatarChange}
                />
                <label htmlFor="avatar-upload">
                  <Button variant="contained" component="span" startIcon={<UploadIcon />}>
                    Upload New Avatar
                  </Button>
                </label>
                {avatar && (
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    sx={{ marginLeft: '10px' }}
                    onClick={handleRemoveAvatar}
                  >
                    Remove Avatar
                  </Button>
                )}
              </Box>
              <Box sx={{ mt: 3, textAlign: 'center' }}>
                {avatar ? (
                  <Avatar
                    src={avatar}
                    alt="User Avatar"
                    sx={{ width: '150px', height: '150px', margin: 'auto', borderRadius: '50%' }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: color,
                      width: '150px',
                      height: '150px',
                      margin: 'auto',
                      fontSize: '64px',
                      color: '#fff',
                    }}
                  >
                    {firstName?.[0]}{lastName?.[0]}
                  </Avatar>
                )}
              </Box>
            </CardContent>
          </Card>
        )}

        {activeTab === 2 && (
          <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Change Password
              </Typography>
              <form onSubmit={handleSavePassword}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="New Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Confirm Password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                  startIcon={<LockIcon />}
                >
                  Save Password
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default UserProfile;
