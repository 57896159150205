import React, { useContext, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { VAVulnerabilityContext } from './VAVulnerabilityContext';
import VulnerabilityDetailsModal from './VulnerabilityDetailsModal';
import VulnerabilityEditModal from './VulnerabilityEditModal';
import { IconButton, Button, Chip, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';

const severityColorMapping = {
  'Critical': '#d32f2f',  // Red
  'High': '#f57c00',      // Orange
  'Medium': '#fbc02d',    // Yellow
  'Low': '#388e3c',       // Green
};

const VAVulnerabilitiesTable = ({ setLoading }) => {
  const { vulnerabilities, setVulnerabilities } = useContext(VAVulnerabilityContext);
  const [selectedVulnerability, setSelectedVulnerability] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [loading, setTableLoading] = useState(true); // Internal loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start the parent component's loading state
        setTableLoading(true); // Start the internal table loading state
        const response = await axios.get('/api/va-vulnerabilities'); // Replace with your actual API call
        setVulnerabilities(response.data);
      } catch (error) {
        console.error('Error fetching vulnerabilities:', error);
      } finally {
        setLoading(false); // End the parent component's loading state
        setTableLoading(false); // End the internal table loading state
      }
    };

    fetchData();
  }, [setLoading, setVulnerabilities]);

  const handleRowClick = (row) => {
    setSelectedVulnerability(row.original);
    setShowDetailsModal(true);
  };

  const handleEditClick = (row) => {
    setSelectedVulnerability(row.original);
    setShowEditModal(true);
  };

  const handleDeleteClick = (row) => {
    setSelectedVulnerability(row.original);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (selectedVulnerability) {
        await axios.delete(`/api/va-vulnerabilities/${selectedVulnerability._id}`);
        setVulnerabilities((prev) => prev.filter((vulnerability) => vulnerability._id !== selectedVulnerability._id));
      } else {
        const selectedIds = Object.keys(rowSelection);
        await Promise.all(selectedIds.map((id) => axios.delete(`/api/va-vulnerabilities/${id}`)));
        setVulnerabilities((prev) => prev.filter((vulnerability) => !selectedIds.includes(vulnerability._id)));
        setRowSelection({});
      }
      setShowDeleteConfirmation(false);
      setSelectedVulnerability(null);
    } catch (err) {
      console.error('Failed to delete vulnerability:', err.message);
    }
  };

  const handleSaveEdit = async (vulnerability, action) => {
    try {
      if (action === 'update') {
        const response = await axios.put(`/api/va-vulnerabilities/${vulnerability._id}`, vulnerability);
        setVulnerabilities((prev) => prev.map((vuln) => (vuln._id === vulnerability._id ? response.data : vuln)));
      } else {
        const response = await axios.post('/api/va-vulnerabilities', vulnerability);
        setVulnerabilities((prev) => [...prev, response.data]);
      }
      setShowEditModal(false);
      setSelectedVulnerability(null);
    } catch (err) {
      console.error('Failed to save vulnerability:', err.message);
    }
  };

  const handleAddNew = () => {
    const newVulnerability = {
      pluginId: '',
      title: '',
      asset: '',
      description: '',
      remediation: '',
      severity: '',
      cve: [],
      status: '',
      dueDate: new Date(),
    };
    setSelectedVulnerability(newVulnerability);
    setShowEditModal(true);
  };

  const handleExport = () => {
    const csvData = vulnerabilities.map((vuln) => ({
      PluginID: vuln.pluginId,
      Title: vuln.title,
      Asset: vuln.asset,
      Severity: vuln.severity,
      Status: vuln.status,
      DueDate: new Date(vuln.dueDate).toLocaleDateString(),
      Description: vuln.description.replace(/[\r\n]+/g, ' ').replace(/,/g, ' '), // Remove new lines and commas for CSV
      Remediation: vuln.remediation.replace(/[\r\n]+/g, ' ').replace(/,/g, ' '),
      CVE: vuln.cve.join(', '),
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(','), // header row
      ...csvData.map((row) => Object.values(row).join(',')), // data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const date = new Date().toISOString().split('T')[0]; // Get date in YYYY-MM-DD format
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `vulnerabilities_export_${date}.csv`;
    link.click();
  };

  const columns = [
    { accessorKey: 'pluginId', header: 'Plugin ID' },
    { accessorKey: 'title', header: 'Title' },
    { accessorKey: 'asset', header: 'Asset' },
    {
      accessorKey: 'severity',
      header: 'Severity',
      Cell: ({ cell }) => (
        <Chip
          label={cell.getValue()}
          sx={{
            backgroundColor: severityColorMapping[cell.getValue()],
            color: '#fff',
            fontWeight: 'bold',
          }}
        />
      ),
    },
    { accessorKey: 'status', header: 'Status' },
    {
      accessorKey: 'dueDate',
      header: 'Due Date',
      Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
    },
  ];

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <MaterialReactTable
          columns={columns}
          data={vulnerabilities}
          getRowId={(row) => row._id}
          muiTableBodyRowProps={{
            sx: {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            },
          }}
          enableRowSelection
          enableRowActions
          enableGrouping
          positionActionsColumn="last"
          enableColumnPinning
          state={{ rowSelection }}
          onRowSelectionChange={setRowSelection}
          renderTopToolbarCustomActions={() => (
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddNew}
              >
                Add New
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleDeleteClick}
                disabled={Object.keys(rowSelection).length === 0 && !selectedVulnerability}
              >
                Delete Selected
              </Button>
              <Button
                color="info"
                variant="outlined"
                startIcon={<FileDownloadIcon />}
                onClick={handleExport}
              >
                Export
              </Button>
            </div>
          )}
          renderRowActions={({ row }) => (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Tooltip title="View Details">
                <IconButton
                  onClick={() => handleRowClick(row)}
                  aria-label="view details"
                  color="primary"
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Vulnerability">
                <IconButton
                  onClick={() => handleEditClick(row)}
                  aria-label="edit"
                  color="secondary"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Vulnerability">
                <IconButton
                  onClick={() => handleDeleteClick(row)}
                  aria-label="delete"
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          initialState={{ density: 'compact', columnPinning: { right: ['mrt-row-actions'], left: ['mrt-row-expand', 'mrt-row-select'] } }}
          muiTablePaperProps={{
            elevation: 3,
            sx: {
              borderRadius: '8px',
            },
          }}
        />
      )}

      {selectedVulnerability && (
        <VulnerabilityDetailsModal
          show={showDetailsModal}
          handleClose={() => setShowDetailsModal(false)}
          vulnerability={selectedVulnerability}
        />
      )}
      {selectedVulnerability && (
        <VulnerabilityEditModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          vulnerability={selectedVulnerability}
          onSave={handleSaveEdit}
        />
      )}
      <Dialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selectedVulnerability ? 'this vulnerability' : 'the selected vulnerabilities'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmation(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VAVulnerabilitiesTable;
