import React, { useContext } from 'react';
import { VAVulnerabilityContext } from './VAVulnerabilityContext';

const VADashboard = () => {
  const { vulnerabilities } = useContext(VAVulnerabilityContext);

  const countSeverity = (severity) => vulnerabilities.filter(vuln => vuln.severity === severity).length;

  return (
    <div className="row">
      {['Critical', 'High', 'Medium', 'Low'].map((severity) => (
        <div className="col-lg-3 col-6" key={severity}>
          <div className={`small-box ${getSeverityColorClass(severity)}`}>
            <div className="inner">
              <h3>{countSeverity(severity)}</h3>
              <p>{severity} Severity</p>
            </div>
            <div className="icon">
              <i className={getSeverityIcon(severity)}></i>
            </div>
            {/* Optionally, you could add a link to view more details about this severity */}
            <a href="#" className="small-box-footer">
              More info <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

const getSeverityColorClass = (severity) => {
  switch (severity) {
    case 'Critical':
      return 'bg-danger'; // red
    case 'High':
      return 'bg-orange'; // yellow-orange
    case 'Medium':
      return 'bg-yellow'; // yellow
    case 'Low':
      return 'bg-success'; // green
    default:
      return 'bg-secondary'; // gray
  }
};

const getSeverityIcon = (severity) => {
  switch (severity) {
    case 'Critical':
      return 'fas fa-skull-crossbones';
    case 'High':
      return 'fas fa-exclamation-circle';
    case 'Medium':
      return 'fas fa-exclamation';
    case 'Low':
      return 'fas fa-check-circle';
    default:
      return 'fas fa-info-circle';
  }
};

export default VADashboard;
