import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  Grid,
} from '@mui/material';

const PtPlanModal = ({ showModal, handleCloseModal, formData, onChange, onSubmit, isEditMode, users }) => {
  return (
    <Dialog open={showModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
      <DialogTitle>{isEditMode ? 'Edit PT Plan' : 'Add New PT Plan'}</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Asset Name"
                name="assetName"
                value={formData.assetName}
                onChange={onChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Owner"
                name="owner"
                value={formData.owner}
                onChange={onChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Status"
                name="status"
                value={formData.status}
                onChange={onChange}
                select
                fullWidth
                required
              >
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="In-Progress">In-Progress</MenuItem>
                <MenuItem value="NA">NA</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Planned Quarter"
                name="plannedQuarter"
                value={formData.plannedQuarter}
                onChange={onChange}
                select
                fullWidth
                required
              >
                <MenuItem value="Q1">Q1</MenuItem>
                <MenuItem value="Q2">Q2</MenuItem>
                <MenuItem value="Q3">Q3</MenuItem>
                <MenuItem value="Q4">Q4</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Start Date"
                name="startDate"
                value={formData.startDate}
                onChange={onChange}
                type="date"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="End Date"
                name="endDate"
                value={formData.endDate}
                onChange={onChange}
                type="date"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Assignee"
                name="assignee"
                value={formData.assignee}
                onChange={onChange}
                select
                fullWidth
                
              >
                <MenuItem value="">Select Assignee</MenuItem>
                {users.map((user) => (
                  <MenuItem key={user._id} value={user._id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              {isEditMode ? 'Update PT Plan' : 'Add PT Plan'}
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PtPlanModal;
