import React, { useState } from 'react';
import { Button, Typography, Box, LinearProgress, Alert } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import axios from 'axios';

const VAUpload = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setMessage(null);
    setError(null);
  };

  const handleUpload = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    setUploading(true);
    setUploadProgress(0);
    setMessage(null);
    setError(null);

    axios.post('/api/va-vulnerabilities/upload', formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      }
    })
      .then(response => {
        setMessage(response.data.message);
        setFile(null);
      })
      .catch(error => setError('Error uploading file: ' + error.message))
      .finally(() => setUploading(false));
  };

  return (
    <Box
      sx={{
        border: '2px dashed #ccc',
        padding: 2,
        borderRadius: 2,
        textAlign: 'center',
        mb: 3,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Upload Qualys or Nessus Report
      </Typography>
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="upload-button"
      />
      <label htmlFor="upload-button">
        <Button
          component="span"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          disabled={uploading}
          sx={{ mt: 1 }}
        >
          Choose File 
        </Button>
      </label>
      {file && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          {file.name}
        </Typography>
      )}
      <br></br>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={!file || uploading}
        sx={{ mt: 2 }}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>

      {uploading && (
        <Box sx={{ mt: 2 }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" sx={{ mt: 1 }}>
            {uploadProgress}% completed
          </Typography>
        </Box>
      )}

      {message && (
        <Alert
          severity="success"
          icon={<CheckCircleIcon fontSize="inherit" />}
          sx={{ mt: 2 }}
        >
          {message}
        </Alert>
      )}

      {error && (
        <Alert
          severity="error"
          icon={<ErrorIcon fontSize="inherit" />}
          sx={{ mt: 2 }}
        >
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default VAUpload;
