import React, { useState, useEffect } from 'react';
import {
  Button, Table, Modal, TextField, MenuItem, Select, FormControl, InputLabel,
  IconButton, Tooltip, Box, Avatar as MuiAvatar
} from '@mui/material';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import { toast } from 'react-toastify';
import AvatarEditor from 'react-avatar-edit';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false); // To toggle color picker visibility
  const [newUser, setNewUser] = useState({
    firstName: '', lastName: '', email: '', role: 'user', password: '', confirmPassword: '', color: '#000000', avatar: ''
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await axios.get('/api/users', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUsers(res.data);
    } catch (err) {
      console.error('Failed to fetch users:', err);
      toast.error('Failed to fetch users');
    }
  };

  const handleAddUser = async () => {
    if (newUser.password !== newUser.confirmPassword) {
      toast.error("Passwords don't match");
      return;
    }
    try {
      await axios.post('/api/users/register', newUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchUsers(); // Refresh the user list
      setOpen(false);
      toast.success('User added successfully');
    } catch (err) {
      console.error('Failed to add user:', err);
      toast.error('Failed to add user');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchUsers(); // Refresh the user list
      toast.success('User deleted successfully');
    } catch (err) {
      console.error('Failed to delete user:', err);
      toast.error('Failed to delete user');
    }
  };

  const handleEditUser = async () => {
    try {
      await axios.put(`/api/users/${selectedUser._id}`, selectedUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchUsers(); // Refresh the user list
      setOpen(false);
      toast.success('User updated successfully');
    } catch (err) {
      console.error('Failed to update user:', err);
      toast.error('Failed to update user');
    }
  };

  const handleOpen = (user = null) => {
    setSelectedUser(user);
    if (user) {
      setNewUser({ ...user, confirmPassword: '' });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewUser({ firstName: '', lastName: '', email: '', role: 'user', password: '', confirmPassword: '', color: '#000000', avatar: '' });
    setShowColorPicker(false);
  };

  const handleAvatarChange = (avatar) => {
    setNewUser({ ...newUser, avatar });
  };

  const handleColorChange = (color) => {
    const updatedColor = color.hex;
    setNewUser({ ...newUser, color: updatedColor });
    if (selectedUser) {
      setSelectedUser({ ...selectedUser, color: updatedColor });
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <h2>User Management</h2>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>Add User</Button>
      <Table sx={{ marginTop: 2 }}>
        <thead>
          <tr>
            <th>Avatar</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>
                <MuiAvatar
                  alt={user.firstName + ' ' + user.lastName}
                  src={user.avatar}
                  sx={{ bgcolor: user.color || '#000' }}
                >
                  {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                </MuiAvatar>
              </td>
              <td style={{ color: user.color }}>{user.firstName}</td>
              <td style={{ color: user.color }}>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <Tooltip title="View">
                  <IconButton color="info" onClick={() => handleOpen(user)}>
                    <Visibility />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton color="primary" onClick={() => handleOpen(user)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton color="error" onClick={() => handleDeleteUser(user._id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 5, backgroundColor: 'white', maxHeight: '80vh', overflowY: 'auto' }}>
          <h3>{selectedUser ? 'Edit User' : 'Add User'}</h3>
          <TextField
            fullWidth
            margin="normal"
            label="First Name"
            value={newUser.firstName}
            onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Last Name"
            value={newUser.lastName}
            onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Role</InputLabel>
            <Select
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          {!selectedUser && (
            <>
              <TextField
                fullWidth
                margin="normal"
                label="Password"
                type="password"
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Confirm Password"
                type="password"
                value={newUser.confirmPassword}
                onChange={(e) => setNewUser({ ...newUser, confirmPassword: e.target.value })}
              />
            </>
          )}
          <Box mt={2}>
            <label>Pick a Color</label>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: newUser.color,
                color: '#fff',
                width: '100%',
                textTransform: 'none',
                justifyContent: 'flex-start',
              }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              {newUser.color}
            </Button>
            {showColorPicker && (
              <SketchPicker
                color={newUser.color}
                onChangeComplete={handleColorChange}
                disableAlpha
              />
            )}
          </Box>
          <Box mt={2}>
            <label>Choose Avatar</label>
            <AvatarEditor
              width={200}
              height={200}
              onCrop={handleAvatarChange}
              onClose={() => setNewUser({ ...newUser, avatar: '' })}
              src={newUser.avatar}
            />
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={selectedUser ? handleEditUser : handleAddUser}>
              {selectedUser ? 'Update User' : 'Add User'}
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClose} sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserManagement;
