import React, { useState } from 'react';
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import { Box, Button as TableButton, ListItemIcon, MenuItem, Select, Avatar, Typography, lighten } from '@mui/material';
import { Edit, Delete, FileDownload } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import Papa from 'papaparse';

const PtPlanTable = ({ ptPlans, setPtPlans, openModal }) => {
  const [rowSelection, setRowSelection] = useState({});

  const deletePtPlan = async (id) => {
    try {
      await axios.delete(`/api/ptPlan/${id}`);
      setPtPlans((prev) => prev.filter((plan) => plan._id !== id));
    } catch (err) {
      console.error(err.response.data);
    }
  };

  const handleBulkDelete = async () => {
    const selectedRows = Object.keys(rowSelection).map((rowId) => ptPlans[rowId]._id); // Use the actual _id
    try {
      await axios.post('/api/ptPlan/bulk-delete', { ids: selectedRows });
      setPtPlans((prev) => prev.filter((ptPlan) => !selectedRows.includes(ptPlan._id)));
      setRowSelection({});
    } catch (err) {
      console.error('Error during deletion:', err);
      alert('Error occurred while deleting PT Plan entry');
    }
  };

  const handleExport = () => {
    const filteredData = ptPlans.map(({ _id, __v, ...rest }) => rest); // Exclude _id and __v
    const csvData = Papa.unparse(filteredData);

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'pt_plans.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    { accessorKey: 'assetName', header: 'Asset Name' },
    { accessorKey: 'owner', header: 'Owner', size: 50 },
    {
      accessorKey: 'plannedQuarter',
      header: 'Planned Quarter',
      size: 30,
      filterVariant: 'multi-select',
      muiTableHeadCellProps: { align: 'center' },
      muiTableBodyCellProps: { align: 'center' },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      filterVariant: 'multi-select',
      size: 50,
      enableEditing: true,
      muiTableHeadCellProps: { align: 'center' },
      muiTableBodyCellProps: { align: 'center' },
      Cell: ({ cell, row }) => {
        const [status, setStatus] = useState(cell.getValue());

        const handleChange = async (event) => {
          const newStatus = event.target.value;
          setStatus(newStatus);
          try {
            const updatedRow = { ...row.original, status: newStatus };
            await axios.put(`/api/ptPlan/${row.original._id}`, updatedRow);
            setPtPlans((prev) => prev.map((plan) => (plan._id === row.original._id ? updatedRow : plan)));
          } catch (error) {
            console.error('Error updating status:', error);
          }
        };

        return (
          <Select
            value={status}
            onChange={handleChange}
            fullWidth
            sx={(theme) => ({
              backgroundColor:
                status === 'Completed'
                  ? theme.palette.success.light
                  : status === 'Pending'
                  ? theme.palette.error.light
                  : status === 'In-Progress'
                  ? theme.palette.warning.light
                  : theme.palette.grey[300],
              color: status === 'NA' ? theme.palette.text.primary : theme.palette.common.white,
              fontSize: '0.875rem',
              padding: '4px 12px',
              borderRadius: '4px',
              textAlign: 'center',
              '& .MuiSelect-select': {
                padding: '4px 12px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            })}
            MenuProps={{
              PaperProps: {
                sx: { zIndex: 1302 },
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
              },
            }}
          >
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="In-Progress">In-Progress</MenuItem>
            <MenuItem value="NA">NA</MenuItem>
          </Select>
        );
      },
    },
    {
      accessorKey: 'assignee',
      header: 'Assignee',
      Cell: ({ cell }) => {
        const assignee = cell.getValue();
        if (!assignee) return 'Unassigned';

        const avatarSrc = assignee.avatar || null;
        const displayColor = assignee.color || '#000000';

        return (
          <Box display="flex" alignItems="center">
            <Avatar
              src={avatarSrc}
              sx={{
                width: 24,
                height: 24,
                mr: 1,
                bgcolor: !avatarSrc ? displayColor : 'transparent',
                color: !avatarSrc ? 'white' : 'black',
              }}
            >
              {!avatarSrc && `${assignee.firstName?.[0]}${assignee.lastName?.[0]}`}
            </Avatar>
            <Typography style={{ color: displayColor }}>{`${assignee.firstName} ${assignee.lastName}`}</Typography>
          </Box>
        );
      },
    },
    {
      accessorKey: 'startDate',
      header: 'Start Date',
      size: 50,
      Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
    },
    {
      accessorKey: 'endDate',
      header: 'End Date',
      size: 50,
      Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
    },
  ];

  return (
    <MaterialReactTable
      columns={columns}
      data={ptPlans}
      enableColumnOrdering
      enableGrouping
      enableColumnPinning
      enableRowActions
      enableRowSelection
      enableGlobalFilter
      enableFacetedValues
      enableRowNumbers
      state={{ rowSelection }}
      onRowSelectionChange={setRowSelection}
      initialState={{ density: 'compact', columnPinning: { right: ['mrt-row-actions'], left: ['mrt-row-expand', 'mrt-row-select'] } }}
          muiTablePaperProps={{
            elevation: 3,
            sx: {
              borderRadius: '8px',
            },
          }}
      paginationDisplayMode="pages"
      positionToolbarAlertBanner="bottom"
      muiSearchTextFieldProps={{ size: 'small', variant: 'outlined' }}
      muiPaginationProps={{
        color: 'secondary',
        rowsPerPageOptions: [10, 30, 100],
        shape: 'rounded',
        variant: 'outlined',
      }}
      editingMode="cell"
      onEditingRowSave={async ({ row, values }) => {
        try {
          await axios.put(`/api/ptPlan/${row.original._id}`, values);
          setPtPlans((prev) => prev.map((plan) => (plan._id === row.original._id ? values : plan)));
        } catch (err) {
          console.error('Error saving changes:', err);
        }
      }}
      renderRowActionMenuItems={({ row, closeMenu }) => [
        <MenuItem
          key={0}
          onClick={() => {
            openModal(row.original);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <Typography>Edit</Typography>
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            deletePtPlan(row.original._id);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <Typography>Delete</Typography>
        </MenuItem>,
      ]}
      renderTopToolbarCustomActions={({ table }) => (
        <div style={{ display: 'flex', gap: '1rem' }}>
            <TableButton
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => openModal()}
            >
              Insert New
            </TableButton>
            <TableButton
              color="error"
              disabled={!Object.keys(rowSelection).length}
              onClick={handleBulkDelete}
              variant="contained"
            >
              Delete Selected
            </TableButton>
            <TableButton
              color="primary"
              onClick={handleExport}
              variant="contained"
              sx={{ ml: 2 }}
              startIcon={<FileDownload />}
            >
              Export CSV
            </TableButton>
          </div>
      )}
    />
  );
};

export default PtPlanTable;
