import React from 'react';

const severityColorMapping = {
  'Critical': 'bg-danger',  // Bootstrap red
  'High': 'bg-warning',     // Bootstrap orange/yellow
  'Medium': 'bg-info',      // Bootstrap light blue
  'Low': 'bg-success',      // Bootstrap green
};

const VulnerabilityDetailsModal = ({ show, handleClose, vulnerability }) => {
  if (!vulnerability) return null;

  return (
    <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} aria-modal="true" role="dialog">
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header sticky-top bg-primary text-white">
            <h5 className="modal-title">
              <i className="fas fa-exclamation-triangle mr-2"></i> Vulnerability Details
            </h5>
            <button type="button" className="close text-white" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ maxHeight: '70vh', overflowY: 'auto', padding: '20px 30px', backgroundColor: '#f4f6f9' }}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <h6 className="text-uppercase text-muted">Plugin ID</h6>
                <p className="font-weight-bold">{vulnerability.pluginId}</p>
              </div>
              <div className="col-md-6 mb-3">
                <h6 className="text-uppercase text-muted">Asset</h6>
                <p className="font-weight-bold">{vulnerability.asset}</p>
              </div>
              <div className="col-md-6 mb-3">
                <h6 className="text-uppercase text-muted">Severity</h6>
                <span className={`badge ${severityColorMapping[vulnerability.severity]} p-2`} style={{ fontSize: '1rem' }}>
                  {vulnerability.severity}
                </span>
              </div>
              <div className="col-md-6 mb-3">
                <h6 className="text-uppercase text-muted">Status</h6>
                <p className="font-weight-bold">{vulnerability.status}</p>
              </div>
              <div className="col-md-12 mb-3">
                <h6 className="text-uppercase text-muted">Description</h6>
                <div className="p-3 border rounded bg-white shadow-sm" dangerouslySetInnerHTML={{ __html: vulnerability.description }} />
              </div>
              <div className="col-md-12 mb-3">
                <h6 className="text-uppercase text-muted">Remediation</h6>
                <div className="p-3 border rounded bg-white shadow-sm" dangerouslySetInnerHTML={{ __html: vulnerability.remediation }} />
              </div>
              <div className="col-md-6 mb-3">
                <h6 className="text-uppercase text-muted">CVE</h6>
                <p className="font-weight-bold">{vulnerability.cve.join(', ')}</p>
              </div>
              <div className="col-md-6 mb-3">
                <h6 className="text-uppercase text-muted">Due Date</h6>
                <p className="font-weight-bold">{new Date(vulnerability.dueDate).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VulnerabilityDetailsModal;
