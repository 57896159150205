import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { Box, Typography, Grid, Card, CardContent, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Avatar, Breadcrumbs, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';

const CalendarView = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [filters, setFilters] = useState({
    ptPlan: true,
    todo: true,
    myEvents: true,
  });

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [events, filters, selectedAssignee]);

  const fetchEvents = async () => {
    try {
      const res = await axios.get('/api/calendar', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const eventsWithColors = res.data.map(event => ({
        ...event,
        backgroundColor: event.assignee?.color || '#3788d8',
        borderColor: '#fff',
      }));

      setEvents(eventsWithColors);

      // Extract unique assignees for filtering
      const uniqueAssignees = [
        ...new Map(
          eventsWithColors.map(event => [
            event.assignee?._id,
            { ...event.assignee },
          ])
        ).values(),
      ];

      setAssignees(uniqueAssignees);
    } catch (err) {
      console.error('Failed to fetch calendar events:', err);
    }
  };

  const applyFilters = () => {
    let filtered = events;

    if (selectedAssignee) {
      filtered = filtered.filter(
        event => event.assignee?._id === selectedAssignee
      );
    }

    if (!filters.ptPlan) {
      filtered = filtered.filter(event => !event.ptPlanId);
    }

    if (!filters.todo) {
      filtered = filtered.filter(event => event.title !== 'To Do');
    }

    if (!filters.myEvents) {
      filtered = filtered.filter(event => event.assignee?._id !== 'currentUserId');
    }

    setFilteredEvents(filtered);
  };

  const handleDateSelect = async (selectInfo) => {
    const title = prompt('Please enter a new title for your event');
    if (title) {
      const newEvent = {
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      };
      try {
        const res = await axios.post('/api/calendar', newEvent, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setEvents([...events, {
          ...res.data,
          backgroundColor: '#3788d8',
          borderColor: '#3788d8',
        }]);
      } catch (err) {
        console.error('Failed to create event:', err);
      }
    }
  };

  const handleEventClick = async (clickInfo) => {
    if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      try {
        await axios.delete(`/api/calendar/${clickInfo.event.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        clickInfo.event.remove();
      } catch (err) {
        console.error('Failed to delete event:', err);
      }
    }
  };

  const handleEventDrop = async (info) => {
    const updatedEvent = {
      start: info.event.start,
      end: info.event.end,
    };

    try {
      await axios.put(`/api/calendar/${info.event.id}`, updatedEvent, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } catch (err) {
      console.error('Failed to update event:', err);
    }
  };

  return (
    <Box sx={{ padding: '20px' }}>
      {/* Breadcrumb */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ backgroundColor: '#f4f6f9',marginBottom: '20px' }}>
          <Link underline="hover" color="inherit" href="/">
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
        
        <Typography color="text.primary">Calendar</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h6" sx={{ marginBottom: '20px' , fontWeight: 'bold',  color: '#adb5bd' }}>
        Calendar
      </Typography>

      <Grid container spacing={3}>
        {/* Filter Panel */}
        <Grid item xs={12} md={3}>
          <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Filters
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Assignee</InputLabel>
                <Select
                  value={selectedAssignee}
                  onChange={(e) => setSelectedAssignee(e.target.value)}
                  label="Assignee"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {assignees.map((assignee) => (
                    <MenuItem key={assignee._id} value={assignee._id}>
                      <Avatar
                        src={assignee.avatar}
                        sx={{ width: 24, height: 24, mr: 1, bgcolor: assignee.color }}
                      >
                        {`${assignee.firstName?.[0]}${assignee.lastName?.[0]}`}
                      </Avatar>
                      {`${assignee.firstName} ${assignee.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.ptPlan}
                    onChange={(e) =>
                      setFilters({ ...filters, ptPlan: e.target.checked })
                    }
                  />
                }
                label="PT Plan Events"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.todo}
                    onChange={(e) =>
                      setFilters({ ...filters, todo: e.target.checked })
                    }
                  />
                }
                label="To Do Tasks"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.myEvents}
                    onChange={(e) =>
                      setFilters({ ...filters, myEvents: e.target.checked })
                    }
                  />
                }
                label="My Events"
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => setFilters({ ptPlan: true, todo: true, myEvents: true })}
              >
                Reset Filters
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Calendar */}
        <Grid item xs={12} md={9}>
          <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
            <CardContent>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                initialView="dayGridMonth"
                selectable={true}
                editable={true}
                events={filteredEvents}
                select={handleDateSelect}
                eventClick={handleEventClick}
                eventDrop={handleEventDrop}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                }}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                }}
                displayEventEnd={true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalendarView;
