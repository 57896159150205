import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import PtPlan from './components/PtPlan';
import PtTeamTools from './components/PtTeamTools';
import PtPasswords from './components/PtPasswords';
import Login from './components/Login';
import Register from './components/Register';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import AdminDashboard from './components/AdminDashboard';
import UserManagement from './components/UserManagement';
import Calendar from './components/CalendarView';
import UserProfile from './components/UserProfile';
import VAVulnerabilities from './components/Vulnerabilities/VA/VAVulnerabilities';

// Axios Interceptor Setup
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';  // Redirect to login if unauthorized
    }
    return Promise.reject(error);
  }
);

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const handleLogin = () => {
    setIsAuthenticated(true);
    window.location.href = '/pt-plan';  // Ensure the user is redirected after login
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    window.location.href = '/login';  // Redirect to login after logout
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/register" element={<Register />} />

        {/* Routes that require authentication */}
        <Route element={isAuthenticated ? <AuthenticatedLayout onLogout={handleLogout} /> : <Navigate to="/login" />}>
          <Route path="/pt-plan" element={<PtPlan />} />
          <Route path="/pt-team-tools" element={<PtTeamTools />} />
          <Route path="/pt-passwords" element={<PtPasswords />} />
          <Route path="/" element={<Navigate to="/pt-plan" />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin/user-management" element={<UserManagement />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/profile" element={<UserProfile />} />
          {/* Vulnerabilities Routes */}
          <Route path="/va-vulnerabilities" element={<VAVulnerabilities />} />
        </Route>
      </Routes>
    </Router>
  );
};

const AuthenticatedLayout = ({ onLogout }) => (
  <>
    <Navbar onLogout={onLogout} />
    <div className="wrapper">
      <Sidebar />
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <Outlet />
          </div>
        </section>
      </div>
      <footer className="main-footer">
        <div className="float-right d-none d-sm-inline">
          Anything you want
        </div>
        <strong>Copyright © 2023 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights reserved.
      </footer>
    </div>
  </>
);

export default App;
