import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const severityOptions = ['Critical', 'High', 'Medium', 'Low'];

const VulnerabilityEditModal = ({ show, handleClose, vulnerability, onSave }) => {
  const [editedVulnerability, setEditedVulnerability] = useState({
    pluginId: '',
    title: '',
    asset: '',
    severity: '',
    dueDate: '',
    description: '',
    remediation: '',
    status: '',
    cve: [],
  });

  useEffect(() => {
    if (show) {
      if (vulnerability && vulnerability._id) {
        // Ensure CVE is treated as an array
        const updatedVulnerability = {
          ...vulnerability,
          cve: Array.isArray(vulnerability.cve) ? vulnerability.cve : [],
        };
        setEditedVulnerability(updatedVulnerability);
      } else {
        // Reset fields for adding a new vulnerability
        setEditedVulnerability({
          pluginId: '',
          title: '',
          asset: '',
          severity: '',
          dueDate: '',
          description: '',
          remediation: '',
          status: '',
          cve: [],  // Initialize CVE as an empty array
        });
      }
    }
  }, [vulnerability, show]);

  const handleChange = (field) => (event) => {
    setEditedVulnerability((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleCveChange = (event) => {
    const value = event.target.value;
    setEditedVulnerability((prev) => ({
      ...prev,
      cve: value.split(',').map((cve) => cve.trim()),  // Convert comma-separated string to an array
    }));
  };

  const handleQuillChange = (field) => (content) => {
    setEditedVulnerability((prev) => ({
      ...prev,
      [field]: content,
    }));
  };

  const handleSave = () => {
    onSave(editedVulnerability, vulnerability._id ? 'update' : 'create');
    handleClose();  // Close the modal after saving
  };

  return (
    <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {vulnerability._id ? 'Edit Vulnerability' : 'Add New Vulnerability'}
            </h5>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <div className="form-group">
              <label>Plugin ID</label>
              <input
                type="text"
                className="form-control"
                value={editedVulnerability.pluginId}
                onChange={handleChange('pluginId')}
              />
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={editedVulnerability.title}
                onChange={handleChange('title')}
              />
            </div>
            <div className="form-group">
              <label>Asset</label>
              <input
                type="text"
                className="form-control"
                value={editedVulnerability.asset}
                onChange={handleChange('asset')}
              />
            </div>
            <div className="form-group">
              <label>Severity</label>
              <select
                className="form-control"
                value={editedVulnerability.severity}
                onChange={handleChange('severity')}
              >
                <option value="" disabled>Select severity</option>
                {severityOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Due Date</label>
              <input
                type="date"
                className="form-control"
                value={editedVulnerability.dueDate ? new Date(editedVulnerability.dueDate).toISOString().split('T')[0] : ''}
                onChange={handleChange('dueDate')}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <ReactQuill
                theme="snow"
                value={editedVulnerability.description || ''}
                onChange={handleQuillChange('description')}
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['link'],
                    ['clean']
                  ]
                }}
              />
            </div>
            <div className="form-group">
              <label>Remediation</label>
              <ReactQuill
                theme="snow"
                value={editedVulnerability.remediation || ''}
                onChange={handleQuillChange('remediation')}
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['link'],
                    ['clean']
                  ]
                }}
              />
            </div>
            <div className="form-group">
              <label>Status</label>
              <input
                type="text"
                className="form-control"
                value={editedVulnerability.status}
                onChange={handleChange('status')}
              />
            </div>
            <div className="form-group">
              <label>CVE</label>
              <input
                type="text"
                className="form-control"
                value={editedVulnerability.cve.join(', ')}  // Convert array to comma-separated string
                onChange={handleCveChange}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VulnerabilityEditModal;
