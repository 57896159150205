import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Modal, Form, Button as ReactButton} from 'react-bootstrap';
import { FaEye, FaEyeSlash, FaCopy } from 'react-icons/fa';
import { MaterialReactTable, useMaterialReactTable, MRT_ToggleFullScreenButton, MRT_ShowHideColumnsButton,MRT_ToggleDensePaddingButton, MRT_GlobalFilterTextField, MRT_ToggleFiltersButton } from 'material-react-table';
import { Box, Button as TableButton, ListItemIcon, MenuItem, Typography, lighten } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import Papa from 'papaparse';

const PtTools = () => {
    const [tools, setTools] = useState([]);
    const [formData, setFormData] = useState({
        toolName: '',
        url: '',
        license: '',
        expDate: '',
        status: '',
        comment: ''
    });
    const [showModal, setShowModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [showTool, setShowTool] = useState(false);

    useEffect(() => {
        fetchTools();
    }, []);

    const fetchTools = async () => {
        try {
            const res = await axios.get('/api/ptTools');
            setTools(res.data);
        } catch (err) {
            console.error(err);
        }
    };
    

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            let res;
            if (isEditMode) {
                res = await axios.put(`/api/ptTools/${currentId}`, formData);
                if (res.data) {
                    setTools(tools.map(tool => (tool._id === currentId ? res.data : tool)));
                } else {
                    throw new Error('Failed to update the tool.');
                }
            } else {
                res = await axios.post('/api/ptTools', formData);
                if (res.data) {
                    setTools([...tools, res.data]);
                } else {
                    throw new Error('Failed to add the new tool.');
                }
            }
            resetForm();
            setShowModal(false);
        } catch (err) {
            console.error('Error occurred during form submission:', err);
        }
    };

    const deleteTool = async (id) => {
        try {
            await axios.delete(`/api/ptTools/${id}`);
            setTools(tools.filter(tool => tool._id !== id));
        } catch (err) {
            console.error(err.response.data);
        }
    };

    const resetForm = () => {
        setFormData({
            toolName: '',
            url: '',
            license: '',
            expDate: '',
            status: '',
            comment: ''
        });
        setCurrentId(null);
    };

    //const togglePasswordVisibility = () => {
    //    setShowPassword(!showPassword);
   // };

    const openModal = (tool = null) => {
        if (tool) {
            setFormData({
                toolName: tool.toolName,
                url: tool.url,
                license: tool.license,
                expDate: tool.expDate,
                status: tool.status,
                comment: tool.comment
            });
            setIsEditMode(true);
            setCurrentId(tool._id);
        } else {
            resetForm();
            setIsEditMode(false);
        }
        setShowModal(true);
    };

    const columns = useMemo(
        () => [
            { accessorKey: 'toolName', header: 'Tool Name' },
            {
                accessorKey: 'url',
                header: 'URL',
                Cell: ({ cell }) => {
                    const url = cell.getValue();
                    if (isValidUrl(url)) {
                    return (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                        {url}
                        </a>
                    );
                    } else {
                    return <span>{url}</span>;
                    }
                },
            },
            { accessorKey: 'license', header: 'License', enableClickToCopy: true },
            { accessorKey: 'expDate', header: 'Expiry Date' },
            { accessorKey: 'status', header: 'Status' },
            { accessorKey: 'comment', header: 'Comment' },
            { accessorKey: 'createdDate', header: 'Created Date', Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString() },
        ],
        []
    );
    function isValidUrl(url) {
        try {
          new URL(url);
          return true;
        } catch (e) {
          return false;
        }
    }
      const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert('Copied to clipboard');
    };

    const table = useMaterialReactTable({
        columns,
        data: tools,
        enableColumnFilterModes: true,
        enableColumnOrdering: true,
        enableGrouping: true,
        enableColumnPinning: true,
        enableFacetedValues: true,
        enableRowActions: true,
        enableRowSelection: true,
        enableRowNumbers: true,
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select'],
                right: ['mrt-row-actions'],
            },
        },
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
            size: 'small',
            variant: 'outlined',
        },
        muiPaginationProps: {
            color: 'secondary',
            rowsPerPageOptions: [10, 20, 30],
            shape: 'rounded',
            variant: 'outlined',
        },
        renderRowActionMenuItems: ({ row, closeMenu }) => [
            <MenuItem
                key={0}
                onClick={() => {
                    openModal(row.original);
                    closeMenu();
                }}
            >
                <ListItemIcon>
                    <Edit />
                </ListItemIcon>
                <Typography>Edit</Typography>
            </MenuItem>,
            <MenuItem
                key={1}
                onClick={() => {
                    deleteTool(row.original._id);
                    closeMenu();
                }}
            >
                <ListItemIcon>
                    <Delete />
                </ListItemIcon>
                <Typography>Delete</Typography>
            </MenuItem>,
        ],
        renderTopToolbar: ({ table }) => {
            const handleBulkDelete = async () => {
                const selectedRows = table.getSelectedRowModel().flatRows;
                const idsToDelete = selectedRows.map(row => row.original._id);
                try {
                    await axios.post('/api/ptTools/bulk-delete', { ids: idsToDelete });
                    setTools(tools.filter(tool => !idsToDelete.includes(tool._id)));
                    table.resetRowSelection();
                } catch (err) {
                    console.error('Error during deletion:', err);
                    alert('Error occurred while deleting tools');
                }
            };
            const handleExport = () => {
                const filteredData = tools.map(({ _id, __v, ...rest }) => rest); // Exclude _id and __v
                const csvData = Papa.unparse(filteredData);

                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'pt_tools.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            };

            return (
                <Box
                    sx={(theme) => ({
                        backgroundColor: lighten(theme.palette.background.default, 0.05),
                        display: 'flex',
                        gap: '0.5rem',
                        p: '8px',
                        justifyContent: 'space-between',
                    })}
                >
                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <MRT_GlobalFilterTextField table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_ToggleFullScreenButton table={table} />
                    </Box>
                    <box>
                        <TableButton
                            color="error"
                            disabled={!table.getIsSomeRowsSelected()}
                            onClick={handleBulkDelete}
                            variant="contained"
                        >
                            Delete Selected
                        </TableButton>
                        <TableButton
                                color="primary"
                                onClick={handleExport}
                                variant="contained"
                                sx={{ ml: 2 }}
                            >
                                Export CSV
                            </TableButton>
                    </box>
                </Box>
            );
        },
    });

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4">PT Team Tools</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active">Penetration Testing Team Tools</li>
            </ol>
            <div className="card mb-4">
                <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    New Tool
                </div>
                <div className="card-body">
                    <ReactButton className="mb-4" onClick={() => setShowModal(true)}>Add New Tool</ReactButton>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Edit Tool' : 'Add New Tool'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlId="formToolName">
                            <Form.Label>Tool Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="toolName"
                                value={formData.toolName}
                                onChange={onChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formURL">
                            <Form.Label>URL</Form.Label>
                            <Form.Control
                                type="link"
                                name="url"
                                value={formData.url}
                                onChange={onChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formLicense">
                            <Form.Label>License</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type="text"
                                    name="license"
                                    value={formData.license}
                                    onChange={onChange}
                                />
                                <ReactButton variant="outline-secondary" onClick={() => copyToClipboard(formData.license)}>
                                    <FaCopy />
                                </ReactButton>
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formExpDate">
                            <Form.Label>Expiry Date</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type={showTool ? "date" : "date"}
                                    name="expDate"
                                    value={formData.expDate}
                                    onChange={onChange}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formStatus">
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                            name="status"
                            value={formData.status}
                            onChange={onChange}
                            
                        >
                            <option value="">Select Status</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>

                        </Form.Select>
                    </Form.Group>
                        <Form.Group controlId="formComment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                type="text"
                                name="comment"
                                value={formData.comment}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <ReactButton variant="primary" type="submit">
                            {isEditMode ? 'Update Tool' : 'Add Tool'}
                        </ReactButton>
                        <ReactButton variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </ReactButton>
                    </Form>
                </Modal.Body>
            </Modal>
            <div className="card mb-4">
                <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    Tools Table
                </div>
                <div className="card-body">
                <MaterialReactTable table={table} />
                </div>
            </div>
        </div>
    );
};

export default PtTools;
