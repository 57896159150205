import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, CardHeader, Breadcrumbs, Typography, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PtPlanDashboard from './PtPlanDashboard';
import PtPlanTable from './PtPlanTable';
import PtPlanModal from './PtPlanModal';
import ExcelUpload from './ExcelUpload';

const PtPlan = () => {
  const [ptPlans, setPtPlans] = useState([]);
  const [formData, setFormData] = useState({
    assetName: '',
    owner: '',
    status: '',
    plannedQuarter: '',
    startDate: '',
    endDate: '',
    assignee: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchPtPlans();
    fetchUsers();
  }, []);

  const fetchPtPlans = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found, redirecting to login');
      const res = await axios.get('/api/ptPlan', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPtPlans(res.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 401) {
        window.location.href = '/login'; // Redirect to login if unauthorized
      }
    }
  };

  const fetchUsers = async () => {
    try {
      const res = await axios.get('/api/users', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUsers(res.data);
    } catch (err) {
      console.error('Failed to fetch users:', err);
    }
  };

  const openModal = (plan = null) => {
    if (plan) {
      setFormData({
        assetName: plan.assetName || '',
        owner: plan.owner || '',
        status: plan.status || '',
        plannedQuarter: plan.plannedQuarter || '',
        startDate: plan.startDate ? plan.startDate.split('T')[0] : '',
        endDate: plan.endDate ? plan.endDate.split('T')[0] : '',
        assignee: plan.assignee ? plan.assignee._id : '',
      });
      setIsEditMode(true);
      setCurrentId(plan._id);
    } else {
      resetForm();
      setIsEditMode(false);
    }
    setShowModal(true);
  };

  const resetForm = () => {
    setFormData({
      assetName: '',
      owner: '',
      status: '',
      plannedQuarter: '',
      startDate: '',
      endDate: '',
      assignee: '',
    });
    setCurrentId(null);
  };

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const selectedAssignee = users.find(user => user._id === formData.assignee);
      const dataToSubmit = {
        ...formData,
        assignee: selectedAssignee ? {
          _id: selectedAssignee._id,
          firstName: selectedAssignee.firstName,
          lastName: selectedAssignee.lastName,
          avatar: selectedAssignee.avatar,
          color: selectedAssignee.color,
        } : null,
      };

      let res;
      if (isEditMode) {
        res = await axios.put(`/api/ptPlan/${currentId}`, dataToSubmit);
        setPtPlans((prev) => prev.map((plan) => (plan._id === currentId ? { ...res.data, assignee: selectedAssignee } : plan)));
      } else {
        res = await axios.post('/api/ptPlan', dataToSubmit);
        setPtPlans((prev) => [...prev, { ...res.data, assignee: selectedAssignee }]);
      }

      resetForm();
      setShowModal(false);
    } catch (err) {
      console.error(err.response?.data || err.message);
    }
  };

  const handleFileUpload = async (data) => {
    setPtPlans((prev) => [...prev, ...data]);
  };

  return (
    <Box sx={{ padding: '20px',  minHeight: '100vh' }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ backgroundColor: '#f4f6f9',marginBottom: '20px' }}>
          <Link underline="hover" color="inherit" href="/">
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Typography color="text.primary">PT Plan</Typography>
        </Breadcrumbs>
        <Typography variant="h6" sx={{ marginBottom: '20px', fontWeight: 'bold', color: '#adb5bd' }}>
          Penetration Testing Annual Plan
        </Typography>
      <PtPlanDashboard ptPlans={ptPlans} />
      

      <PtPlanModal
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        formData={formData}
        onChange={onChange}
        onSubmit={onSubmit}
        isEditMode={isEditMode}
        users={users}
      />

      <Box className="card mb-4">
        <Box className="card-header">
          <i className="fas fa-table me-1"></i>
          PT Plan Table
        </Box>
        <Box className="card-body">
            <ExcelUpload onFileUpload={handleFileUpload} />
            <PtPlanTable ptPlans={ptPlans} setPtPlans={setPtPlans} openModal={openModal} />
        </Box>
      </Box>
    </Box>
  );
};

export default PtPlan;
