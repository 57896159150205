import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

const Sidebar = () => {
  const [user, setUser] = useState({});
  const [openPTTeam, setOpenPTTeam] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const res = await axios.get('/api/users/me', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setUser(res.data);
      } catch (err) {
        console.error('Failed to fetch user profile:', err);
      }
    };

    fetchUserProfile();
  }, []);

  const handlePTTeamClick = () => {
    setOpenPTTeam(!openPTTeam);
  };

  const handleAdminClick = () => {
    setOpenAdmin(!openAdmin);
  };

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <a href="#" className="brand-link">
        <img
          src={`${process.env.PUBLIC_URL}/dist/img/AdminLTELogo.png`}
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: '.8' }}
        />
        <span className="brand-text font-weight-light">PT Team</span>
      </a>

      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={user.avatar}
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <NavLink to="/profile" className="d-block" style={{ color: user.color || '#ffffff' }}>
              {user.firstName ? `${user.firstName} ${user.lastName}` : 'User Name'}
            </NavLink>
          </div>
        </div>

        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-item">
              <NavLink to="/" className="nav-link">
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>Dashboard</p>
              </NavLink>
            </li>

            {/* PT Team Section */}
            <li className="nav-item has-treeview">
              <a href="#" className="nav-link" onClick={handlePTTeamClick}>
                <i className="nav-icon fas fa-users" />
                <p>
                  PT Team
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview" style={{ display: openPTTeam ? 'block' : 'none' }}>
                <li className="nav-item">
                  <NavLink to="/pt-plan" className="nav-link">
                    <i className="far fa-file nav-icon" />
                    <p>PT Plan</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/pt-team-tools" className="nav-link">
                    <i className="fas fa-wrench nav-icon" />
                    <p>PT Team Tools</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/pt-passwords" className="nav-link">
                    <i className="fas fa-lock nav-icon" />
                    <p>PT Passwords</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/calendar" className="nav-link">
                    <i className="far fa-calendar nav-icon" />
                    <p>Calendar</p>
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* Vulnerabilities Section */}
            <li className="nav-item">
              <NavLink to="/va-vulnerabilities" className="nav-link">
                <i className="nav-icon fas fa-bug" />
                <p>VA Vulnerabilities</p>
              </NavLink>
            </li>

            {/* Admin Section */}
            {user.role === 'admin' && (
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link" onClick={handleAdminClick}>
                  <i className="nav-icon fas fa-cog" />
                  <p>
                    Admin
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview" style={{ display: openAdmin ? 'block' : 'none' }}>
                <li className="nav-item">
                    <NavLink to="/admin-dashboard" className="nav-link">
                      <i className="nav-icon fas fa-tachometer-alt" />
                      <p>Admin Dashboard</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/user-management" className="nav-link">
                      <i className="nav-icon fas fa-users" />
                      <p>User Management</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;