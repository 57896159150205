import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Chart } from 'chart.js/auto';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { useTheme } from '@mui/material/styles';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels); // Register the DataLabels plugin

const PtPlanDashboard = ({ ptPlans }) => {
    const chartRefStatus = useRef(null);
    const chartRefQuarterly = useRef(null);
    const chartRefAssignee = useRef(null);
    const [selectedAssignee, setSelectedAssignee] = useState('');
    const [minimized, setMinimized] = useState({ status: false, quarterly: false, assignee: false });
    const theme = useTheme();

    useEffect(() => {
        if (ptPlans.length > 0) {
            createCharts();
        }
        // Cleanup on component unmount to avoid memory leaks
        return () => {
            if (chartRefStatus.current) chartRefStatus.current.destroy();
            if (chartRefQuarterly.current) chartRefQuarterly.current.destroy();
            if (chartRefAssignee.current) chartRefAssignee.current.destroy();
        };
    }, [ptPlans, selectedAssignee]);

    const createCharts = () => {
        if (!minimized.status) createStatusChart();
        if (!minimized.quarterly) createQuarterlyChart();
        if (!minimized.assignee) createAssigneeChart();
    };

    const toggleMinimize = (chart) => {
        setMinimized((prevState) => ({ ...prevState, [chart]: !prevState[chart] }));
    };

    const createStatusChart = () => {
        if (chartRefStatus.current) {
            chartRefStatus.current.destroy();
        }

        const statusCounts = ptPlans.reduce((acc, plan) => {
            acc[plan.status] = (acc[plan.status] || 0) + 1;
            return acc;
        }, {});

        const ctx = document.getElementById('statusChart').getContext('2d');
        chartRefStatus.current = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: Object.keys(statusCounts),
                datasets: [{
                    label: 'Status Count',
                    data: Object.values(statusCounts),
                    backgroundColor: [theme.palette.success.light, theme.palette.error.light, theme.palette.warning.light, theme.palette.grey[300]],
                    borderColor: '#f8f9fa',
                    borderWidth: 1
                }]
            },
            options: {
                plugins: {
                    datalabels: {
                        color: '#fff',
                        font: {
                            weight: 'bold',
                            size: 16,
                        },
                        formatter: (value) => value, // Show the data value
                    },
                },
                maintainAspectRatio: false,
            }
        });
    };

    const createQuarterlyChart = () => {
        if (chartRefQuarterly.current) {
            chartRefQuarterly.current.destroy();
        }

        const quarterlyCounts = ptPlans.reduce((acc, plan) => {
            const quarter = plan.plannedQuarter;
            acc[quarter] = (acc[quarter] || 0) + (plan.status === 'Completed' ? 1 : 0);
            return acc;
        }, {});

        const ctx = document.getElementById('quarterlyChart').getContext('2d');
        chartRefQuarterly.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Q1', 'Q2', 'Q3', 'Q4'],
                datasets: [{
                    label: 'Quarterly Completed',
                    data: [quarterlyCounts['Q1'] || 0, quarterlyCounts['Q2'] || 0, quarterlyCounts['Q3'] || 0, quarterlyCounts['Q4'] || 0],
                    backgroundColor: [theme.palette.primary.light, theme.palette.secondary.light, theme.palette.info.light, theme.palette.warning.light],
                    borderColor: [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.info.main, theme.palette.warning.main],
                    borderWidth: 1
                }]
            },
            options: {
                plugins: {
                    datalabels: {
                        color: '#000',
                        font: {
                            weight: 'bold',
                            size: 16,
                        },
                        formatter: (value) => value,
                    },
                },
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            }
        });
    };

    const createAssigneeChart = () => {
        if (chartRefAssignee.current) {
            chartRefAssignee.current.destroy();
        }

        const assigneeData = ptPlans.reduce((acc, plan) => {
            if (selectedAssignee && plan.assignee && `${plan.assignee.firstName} ${plan.assignee.lastName}` !== selectedAssignee) {
                return acc;
            }
            const assigneeName = plan.assignee ? `${plan.assignee.firstName} ${plan.assignee.lastName}` : 'Unassigned';
            acc[assigneeName] = acc[assigneeName] || { Completed: 0, 'In-Progress': 0, Pending: 0, NA: 0 };
            acc[assigneeName][plan.status] = (acc[assigneeName][plan.status] || 0) + 1;
            return acc;
        }, {});

        const labels = Object.keys(assigneeData);

        const ctx = document.getElementById('assigneeChart').getContext('2d');
        chartRefAssignee.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels,
                datasets: [
                    {
                        label: 'Completed',
                        data: labels.map(assignee => assigneeData[assignee].Completed),
                        backgroundColor: theme.palette.success.light,
                        borderColor: theme.palette.success.main,
                        borderWidth: 1
                    },
                    {
                        label: 'In-Progress',
                        data: labels.map(assignee => assigneeData[assignee]['In-Progress']),
                        backgroundColor: theme.palette.warning.light,
                        borderColor: theme.palette.warning.main,
                        borderWidth: 1
                    },
                    {
                        label: 'Pending',
                        data: labels.map(assignee => assigneeData[assignee].Pending),
                        backgroundColor: theme.palette.error.light,
                        borderColor: theme.palette.error.main,
                        borderWidth: 1
                    },
                    {
                        label: 'NA',
                        data: labels.map(assignee => assigneeData[assignee].NA),
                        backgroundColor: theme.palette.info.light,
                        borderColor: theme.palette.info.main,
                        borderWidth: 1
                    }
                ]
            },
            options: {
                plugins: {
                    datalabels: {
                        color: '#000',
                        font: {
                            weight: 'bold',
                            size: 16,
                        },
                        formatter: (value) => value,
                    },
                },
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            }
        });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
                    <CardHeader
                        title="Status Chart"
                        action={
                            <IconButton onClick={() => toggleMinimize('status')}>
                                <MinimizeIcon />
                            </IconButton>
                        }
                        titleTypographyProps={{ variant: 'subtitle1', color: theme.palette.text.secondary }}
                        sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, padding: '10px 16px' }}
                    />
                    {!minimized.status && (
                        <CardContent>
                            <Box sx={{ height: 300 }}>
                                <canvas id="statusChart"></canvas>
                            </Box>
                        </CardContent>
                    )}
                </Card>
            </Grid>

            <Grid item xs={12} md={6}>
                <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
                    <CardHeader
                        title="Quarterly Completed"
                        action={
                            <IconButton onClick={() => toggleMinimize('quarterly')}>
                                <MinimizeIcon />
                            </IconButton>
                        }
                        titleTypographyProps={{ variant: 'subtitle1', color: theme.palette.text.secondary }}
                        sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, padding: '10px 16px' }}
                    />
                    {!minimized.quarterly && (
                        <CardContent>
                            <Box sx={{ height: 300 }}>
                                <canvas id="quarterlyChart"></canvas>
                            </Box>
                        </CardContent>
                    )}
                </Card>
            </Grid>

            <Grid item xs={12} md={12}>
                <Card sx={{ borderRadius: '8px', boxShadow: 3 }}>
                    <CardHeader
                        title="Assignee Status"
                        action={
                            <>
                                <FormControl sx={{ minWidth: 120, mr: 2 }}>
                                    <InputLabel>Assignee</InputLabel>
                                    <Select
                                        value={selectedAssignee}
                                        onChange={(e) => setSelectedAssignee(e.target.value)}
                                        label="Assignee"
                                    >
                                        <MenuItem value="">
                                            <em>All</em>
                                        </MenuItem>
                                        {Object.keys(ptPlans.reduce((acc, plan) => {
                                            if (plan.assignee) {
                                                const name = `${plan.assignee.firstName} ${plan.assignee.lastName}`;
                                                acc[name] = true;
                                            }
                                            return acc;
                                        }, {})).map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <IconButton onClick={() => toggleMinimize('assignee')}>
                                    <MinimizeIcon />
                                </IconButton>
                            </>
                        }
                        titleTypographyProps={{ variant: 'subtitle1', color: theme.palette.text.secondary }}
                        sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, padding: '10px 16px' }}
                    />
                    {!minimized.assignee && (
                        <CardContent>
                            <Box sx={{ height: 400 }}>
                                <canvas id="assigneeChart"></canvas>
                            </Box>
                        </CardContent>
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};

export default PtPlanDashboard;
