import React, { createContext, useState, useEffect, useMemo } from 'react';
import axios from 'axios';

export const VAVulnerabilityContext = createContext();

export const VAVulnerabilityProvider = ({ children, apiEndpoint = '/api/va-vulnerabilities' }) => {
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVulnerabilities = async () => {
      setLoading(true);
      try {
        const response = await axios.get(apiEndpoint);
        setVulnerabilities(response.data);
      } catch (error) {
        console.error('Error fetching vulnerabilities:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchVulnerabilities();
  }, [apiEndpoint]);

  const value = useMemo(() => ({
    vulnerabilities,
    setVulnerabilities,
    loading,
    error,
  }), [vulnerabilities, loading, error]);

  return (
    <VAVulnerabilityContext.Provider value={value}>
      {children}
    </VAVulnerabilityContext.Provider>
  );
};
